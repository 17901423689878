import { Box, Typography } from "@mui/material";
import { graphql } from "../../../api/__generated__";
import { useQuery } from "@apollo/client";

const matchConsultationQuery = graphql(`
  query MatchConsultation {
    currentMatchedHuman {
      id
      matchConsultation {
        id
        professional {
          avatar {
            url
          }
          id
          user {
            firstName
            id
            lastName
          }
        }
        scheduledFrom
      }
    }
  }
`);

const EndMatchingStep = () => {
  const { data } = useQuery(matchConsultationQuery);

  return (
    <Box sx={{ height: "100%", textAlign: "center", width: "100%" }}>
      <Typography variant="h1"> END MATCHING </Typography>
      you have a consult with{" "}
      {
        data?.currentMatchedHuman?.matchConsultation?.professional?.user
          .firstName
      }
    </Box>
  );
};

export default EndMatchingStep;
