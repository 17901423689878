import { Box, Typography, useTheme } from "@mui/material";

interface ChipProps {
  light?: boolean;
  text: string;
}

const Chip = ({ light, text }: ChipProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: light
          ? theme.palette.black.light
          : theme.palette.green.main,
        borderRadius: "16px",
        display: "inline-block",
        minWidth: "100px",
        padding: "2px 16px",
      }}
    >
      <Typography
        sx={{
          color: theme.palette.white.light,
          fontWeight: 600,
          letterSpacing: " 0.8px",
          textAlign: "center",
          textTransform: "uppercase",
          textWrap: "nowrap",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default Chip;
