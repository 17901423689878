import { Box } from "@mui/material";
import { graphql } from "../../../api/__generated__";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import Header from "./Common/Header";
import StepHeader from "./Common/StepHeader";
import Footer from "./Common/Footer";
import StepMainContent from "./Common/StepMainContent";
import BloomieCard from "./Common/BloomieCard";

const subtopicsQuery = graphql(`
  query Subtopics {
    currentMatchedHuman {
      id
      noSubtopic
      subtopic {
        id
      }
      topic {
        descriptionTranslationKey
        id
        imageUrl
        subtopics {
          descriptionTranslationKey
          id
          imageUrl
          titleTranslationKey
        }
        titleTranslationKey
      }
    }
  }
`);

const submitSubtopicStepMutation = graphql(`
  mutation SubmitSubtopicStep($subtopicId: String) {
    submitSubtopicStep(subtopicId: $subtopicId) {
      matchedHuman {
        id

        noSubtopic
        subtopic {
          id
        }
        treatments {
          availableTreatments
          module {
            id
          }
          proposedTreatment
        }
      }

      matchingToken

      nextStep
    }
  }
`);

interface SubTopicStepProps {
  setNextStep: (nextStep: string) => void;
  stepBack: () => void;
}

const SubtopicStep = ({ setNextStep, stepBack }: SubTopicStepProps) => {
  const { t } = useTranslation();
  const { data: topicData } = useQuery(subtopicsQuery, {
    onCompleted: (data) => {
      const matchedHuman = data.currentMatchedHuman;

      if (matchedHuman?.noSubtopic) {
        setChoice(null);
      } else {
        setChoice(matchedHuman?.subtopic?.id);
      }
    },
  });

  const [submitSubtopicStep] = useMutation(submitSubtopicStepMutation);
  const [choice, setChoice] = useState<string | null | undefined>();

  const topic = topicData?.currentMatchedHuman?.topic;

  return (
    <Box sx={{ height: "100%", textAlign: "center", width: "100%" }}>
      <Header />
      <StepHeader
        imageUrl={topic?.imageUrl || ""}
        subtitle={t(topic?.descriptionTranslationKey || "")}
        title={t(topic?.titleTranslationKey || "")}
      />
      <StepMainContent
        enableNext={choice !== undefined}
        onClickNext={async () => {
          const { data: submitData } = await submitSubtopicStep({
            variables: {
              subtopicId: choice,
            },
          });

          submitData?.submitSubtopicStep.nextStep &&
            setNextStep(submitData.submitSubtopicStep.nextStep);

          submitData?.submitSubtopicStep.matchingToken &&
            localStorage.setItem(
              "matchingToken",
              submitData.submitSubtopicStep.matchingToken,
            );
        }}
        onClickPrevious={stepBack}
      >
        <Box
          sx={{
            alignItems: "baseline",
            display: "flex",
            flexWrap: "wrap",
            gap: "35px 62px",
            justifyContent: "center",
            maxWidth: "1100px",
            paddingY: "1rem",
          }}
        >
          {topic?.subtopics.map((subTopic) => (
            <BloomieCard
              bodyText={t(subTopic.descriptionTranslationKey)}
              checked={subTopic.id === choice}
              imgUrl={subTopic.imageUrl}
              key={subTopic.id}
              onClick={() => {
                setChoice(subTopic.id);
              }}
              title={t(subTopic.titleTranslationKey)}
              type="outlined"
            />
          ))}
          <BloomieCard
            bodyText={t("topics:something_else.description")}
            checked={choice === null}
            imgUrl={
              "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D63-dont-know%2C+Size%3DSmall.png"
            }
            key={0}
            onClick={() => {
              setChoice(null);
            }}
            title={t("topics:something_else.title")}
            type="outlined"
          />
        </Box>
      </StepMainContent>
      <Footer />
    </Box>
  );
};

export default SubtopicStep;
