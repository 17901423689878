import { Box, SxProps, Typography, useTheme } from "@mui/material";

import BloomieCard from "../Common/BloomieCard";
import { useTranslation } from "react-i18next";

const ConsultationRules = (sx: SxProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginY: "1.5rem",
        textAlign: "center",
      }}
    >
      <Typography sx={{ marginY: "1rem" }} variant="h2">
        {t("match:chooseProfStep.rules.title")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          [theme.breakpoints.down(1200)]: {
            flexWrap: "wrap",
            justifyContent: "center",
          },
          gap: "2rem 3.5rem",
          [theme.breakpoints.down(800)]: {
            gap: "1rem 0.5rem",
          },
          justifyContent: "space-around",
          width: "100%",
          [theme.breakpoints.up(1199)]: { ...sx }, //iknow, nasty
        }}
      >
        <BloomieCard
          bodyText={t("match:chooseProfStep.rules.1.body")}
          imgUrl="https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D30-planning%2C+Size%3DSmall.png"
          sx={{
            "& .MuiTypography-body1": {
              fontSize: "16px",
            },
            "& .MuiTypography-h4": {
              fontSize: "24px",
              [theme.breakpoints.up(600)]: { textWrap: "nowrap" },
            },
            [theme.breakpoints.up(500)]: { minWidth: "325px" },
          }}
          title={t("match:chooseProfStep.rules.1.title")}
          type="blank"
        />
        <BloomieCard
          bodyText={t("match:chooseProfStep.rules.2.body")}
          imgUrl="https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D48-talk-to-professional%2C+Size%3DSmall.png"
          sx={{
            "& .MuiTypography-body1": {
              fontSize: "16px",
            },
            "& .MuiTypography-h4": {
              fontSize: "24px",
              [theme.breakpoints.up(600)]: { textWrap: "nowrap" },
            },
            [theme.breakpoints.up(500)]: { minWidth: "325px" },
          }}
          title={t("match:chooseProfStep.rules.2.title")}
          type="blank"
        />
        <BloomieCard
          bodyText={t("match:chooseProfStep.rules.3.body")}
          imgUrl="https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D08-pausing%2C+Size%3DSmall.png"
          sx={{
            "& .MuiTypography-body1": {
              fontSize: "16px",
            },
            "& .MuiTypography-h4": {
              fontSize: "24px",
              [theme.breakpoints.up(600)]: { textWrap: "nowrap" },
            },
            [theme.breakpoints.up(500)]: { minWidth: "325px" },
          }}
          title={t("match:chooseProfStep.rules.3.title")}
          type="blank"
        />
      </Box>
    </Box>
  );
};

export default ConsultationRules;
