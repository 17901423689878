import { useContext } from "react";
import { styled } from "@mui/material/styles";
import { Typography, useMediaQuery, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import { ILandingPageContext } from "../context/organization";
import LandingPageContext from "../context/LandingPageContext";

import SanitizedText from "../../../ui/text/SanitizedText";

import MatchingBlockLiedekerke from "../custom/liedekerke/MatchingBlockLiedekerke";
import PartnerTextBlock from "./content-blocks/PartnerTextBlock";
import PartnerMatchingBlock from "./content-blocks/PartnerMatchingBlock";
import PartnerReviewBlock from "./PartnerReviewBlock";
import PartnerListBlock from "./content-blocks/PartnerListBlock";
import MatchingBlockEmail from "./matching-blocks/MatchingBlockEmail";
import MatchingBlockEmpty from "./matching-blocks/MatchingBlockEmpty";
import PartnerGGTBlock from "./content-blocks/PartnerGGTBlock";

const PREFIX = "PartnerPageContentBlocks";

const classes = {
  content: `${PREFIX}-content`,
  root: `${PREFIX}-root`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    paddingLeft: 115,
    paddingRight: 115,
    position: "relative",

    [theme.breakpoints.down("md")]: {
      paddingLeft: 70,
      paddingRight: 70,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },

  [`& .${classes.content}`]: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: -150,
    maxWidth: 1024,
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      marginTop: -180,
    },
  },
}));

export enum PartnerPageContentBlockTypes {
  ggt = "ggt",
  list = "list",
  matching = "matching",
  partner = "partner",
  review = "review",
  text = "text",
}

export interface PartnerPageContentBlock {
  avatarSrc?: string | undefined;
  backgroundColor: string;
  color?: string;
  description?: string;
  link?: string;
  title?: string;
  type: PartnerPageContentBlockTypes;
}

interface IPartnerPageBody {
  color: string;
  leftBlocks: Array<PartnerPageContentBlock>;
  rightBlocks: Array<PartnerPageContentBlock>;
}

const StartMatchingBlock = (slug?: string) => {
  if (slug === "" || slug === undefined) return <MatchingBlockEmpty />;

  if (slug === "liedekerke") {
    return <MatchingBlockLiedekerke />;
  } else if (
    [
      "cm",
      "mc",
      "bpostbanque",
      "ksa",
      "klj",
      "elia",
      "skyscrapers",
      "liedekerke",
      "assaabloy",
      "hogent",
    ].includes(slug)
  ) {
    return <MatchingBlockEmail />;
  }

  return <MatchingBlockEmpty />;
};

const PartnerPageContentBlocks = ({
  leftBlocks,
  rightBlocks,
  color,
}: IPartnerPageBody) => {
  const { slug } = useContext<ILandingPageContext>(LandingPageContext);
  const small = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const getJsxElementByType = (block: PartnerPageContentBlock) => {
    switch (block.type) {
      case PartnerPageContentBlockTypes.text:
        return <PartnerTextBlock block={block} color={color} />;

      case PartnerPageContentBlockTypes.ggt:
        return <PartnerGGTBlock block={block} color={color} />;

      case PartnerPageContentBlockTypes.matching:
        return (
          <PartnerMatchingBlock
            block={block}
            color={color}
            matchingBlock={StartMatchingBlock(slug)}
          />
        );

      case PartnerPageContentBlockTypes.review:
        return (
          <PartnerReviewBlock
            avatarSrc={block.avatarSrc}
            children={
              block.description && (
                <Typography>
                  <SanitizedText input={block.description.toString()} />
                </Typography>
              )
            }
            color={color}
            name={block.title ? block.title : ""}
          />
        );

      case PartnerPageContentBlockTypes.list:
        return <PartnerListBlock block={block} color={color} />;
    }
  };

  return (
    <StyledBox className={classes.root}>
      <Box
        className={classes.content}
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        {small ? (
          <Box display="flex" flexDirection="column">
            {[...leftBlocks, ...rightBlocks]
              .filter((block) => block !== undefined)
              .map((block) => getJsxElementByType(block))}
          </Box>
        ) : (
          <>
            <Box display="flex" flexDirection="column">
              {leftBlocks.map((block) => getJsxElementByType(block))}
            </Box>
            <Box display="flex" flexDirection="column">
              {rightBlocks.map((block) => getJsxElementByType(block))}
            </Box>
          </>
        )}
      </Box>
    </StyledBox>
  );
};

export default PartnerPageContentBlocks;
