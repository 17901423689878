import { Avatar, Box, Typography, useTheme } from "@mui/material";
import useSelectTranslation from "../../../../api/languages/useSelectTranslation";
import {
  differenceInCalendarDays,
  differenceInYears,
  format,
  formatRelative,
} from "date-fns";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Chip from "./Chip";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../../../api/__generated__";
import ChooseConsultation from "./ChooseConsultation";
import { IntroDetails } from "../Match";
import { useState } from "react";
import Button from "../../../ui/buttons/Button";
import TodayIcon from "@mui/icons-material/Today";
import ConsultationRules from "./ConsultationRules";
import { getDateFnsLocale } from "../../../language/languagesUtil";

const matchOrganizationFragment = graphql(`
  fragment MatchOrganization on Organization {
    coachingsPerHuman
    consultsPerHuman
    contractStartDate
    name {
      ...useSelectTranslationStringInput
    }
  }
`);

const matchProfFragment = graphql(`
  fragment MatchProf on Professional {
    availabilityV2(amountOfDaysInTheFuture: 31, consultationType: INTRO)
    avatar {
      url
    }
    coachingEnabled
    consultationLanguages
    experienceSince
    gender
    id
    isOnline
    primarySubtopics {
      id
      titleTranslationKey
    }
    profileDescription {
      ...useSelectTranslationStringInput
    }
    secondarySubtopics {
      id
      titleTranslationKey
    }
    standardDuration
    standardRate
    type
    user {
      firstName
      id
      lastName
    }
    website
  }
`);

interface ProfCardProps {
  bookIntroConsult: (introDetails: IntroDetails) => Promise<void>;
  chosenSubTopicId?: string;
  organization?: FragmentType<typeof matchOrganizationFragment>;
  prof: FragmentType<typeof matchProfFragment>;
  spotlight?: boolean;
}

const ProfCard = ({
  bookIntroConsult,
  spotlight,
  prof,
  chosenSubTopicId,
  organization,
}: ProfCardProps) => {
  const selectTranslation = useSelectTranslation();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const dateFnsLocale = getDateFnsLocale(i18n.language);

  const { name, consultsPerHuman, contractStartDate } =
    getFragmentData(matchOrganizationFragment, organization) || {};

  const profFragment = getFragmentData(matchProfFragment, prof);

  const {
    availabilityV2,
    coachingEnabled,
    consultationLanguages,
    //isOnline,
    primarySubtopics,
    profileDescription,
    secondarySubtopics,
    user,
    avatar,
    id: profId,
    experienceSince,
    gender,
    standardDuration,
    standardRate,
    type,
    website,
  } = profFragment;

  const firstDate = availabilityV2[0] ? new Date(availabilityV2[0]) : undefined;

  const [showBookPane, setShowBookPane] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        maxWidth: "900px",
        textAlign: "left",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          [theme.breakpoints.down(400)]: { flexDirection: "column" },
          gap: "25px",
        }}
      >
        {avatar?.url ? (
          <Avatar
            alt={user.firstName + " " + user.lastName}
            src={avatar.url}
            sx={{
              border: "1px",
              borderColor: spotlight
                ? theme.palette.green.dark
                : theme.palette.bloom.dark,
              height: "200px",
              width: "200px",
              [theme.breakpoints.down(750)]: {
                height: "120px",
                width: "120px",
              },
              [theme.breakpoints.down(500)]: {
                height: "100px",
                width: "100px",
              },
            }}
          />
        ) : (
          <Avatar
            alt={user.firstName + " " + user.lastName}
            sx={{
              border: "1px",
              borderColor: spotlight
                ? theme.palette.green.dark
                : theme.palette.bloom.dark,
              height: "200px",
              width: "200px",
              [theme.breakpoints.down(750)]: {
                height: "120px",
                width: "120px",
              },
              [theme.breakpoints.down(500)]: {
                height: "100px",
                width: "100px",
              },
            }}
          >
            {user.firstName}
          </Avatar>
        )}

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{ [theme.breakpoints.down(750)]: { fontSize: "24px" } }}
            variant="h1"
          >
            {user.firstName + " " + user.lastName}
          </Typography>
          {gender && (
            <Typography sx={{ fontSize: "16px" }} variant="caption">
              {t("match:gender." + gender)}
            </Typography>
          )}
          <Typography sx={{ fontSize: "16px" }} variant="caption">
            {t("match:languages") + " "}
            {consultationLanguages.map((lang, index) => {
              return (
                t("match:languages." + lang) +
                (index !== consultationLanguages.length - 1 ? ", " : "")
              );
            })}
          </Typography>
          <Typography
            sx={{
              [theme.breakpoints.down(750)]: {
                display: "none",
              },
            }}
            variant="body1"
          >
            {selectTranslation(profileDescription)}
          </Typography>
          {website && (
            <Typography variant="body1">
              {t("match:chooseProfStep.card.website") + " "}
              <Link target="blank" to={"https://" + website}>
                {website}
              </Link>
            </Typography>
          )}
        </Box>
      </Box>
      <Typography
        sx={{
          [theme.breakpoints.up(750)]: {
            display: "none",
          },
        }}
        variant="body1"
      >
        {selectTranslation(profileDescription)}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          [theme.breakpoints.up(750)]: { paddingLeft: "20px" },
        }}
      >
        <Typography variant="body1">
          {t(
            "match:chooseProfStep.card.experience" +
              (coachingEnabled && "_coaching"),
            {
              experience: differenceInYears(Date.now(), experienceSince || ""),
              firstName: user.firstName,
              role: t(`common:${type}`),
            },
          )}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px 20px",
            [theme.breakpoints.up(750)]: { paddingBottom: "20px" },
          }}
        >
          {primarySubtopics.map((topic) => {
            return (
              <Chip
                key={topic.id}
                light={topic.id !== chosenSubTopicId}
                text={t(topic.titleTranslationKey)}
              />
            );
          })}
          {secondarySubtopics.map((topic, index) => {
            return (
              <Chip key={index} light text={t(topic.titleTranslationKey)} />
            );
          })}
        </Box>
      </Box>
      {
        <Typography>
          {t("match:chooseProfStep.card.credits", {
            consultCount: consultsPerHuman,
            consultDuration: standardDuration,
            consultPrice: standardRate,
            contractStart: format(contractStartDate || "", "dd/MM"),
            orgName: selectTranslation(name),
            profName: user.firstName,
          })}
        </Typography>
      }
      <Box
        sx={{
          alignSelf: "stretch",
          display: "flex",
          gap: "14px",
          justifyContent: "center",
          paddingTop: "30px",
          [theme.breakpoints.down(600)]: {
            flexWrap: "wrap",
          },
        }}
      >
        {/* {isOnline ? (     ---> ENG-1300
          <Button
            bloomColor="green"
            fullyColored
            icon={<VideocamIcon />}
            label={t("match:chooseProfstep.card.chooseConsultation.startNow")}
            size="medium"
            sx={{ fontSize: "16px",  [theme.breakpoints.up(400)]: { textWrap: "nowrap" }, }}
          />
        ) : ( */}
        <Button
          fullyColored
          icon={<TodayIcon />}
          label={t("match:chooseProfstep.card.nextConsult.button", {
            time: firstDate
              ? differenceInCalendarDays(firstDate, new Date()) < 7
                ? formatRelative(firstDate, new Date(), {
                    locale: dateFnsLocale,
                  })
                : format(firstDate, "dd MMM HH:mm", { locale: dateFnsLocale })
              : undefined,
          })}
          onClick={() => {
            bookIntroConsult({
              professionalId: profId,
              startTime: new Date(availabilityV2[0]),
            });
          }}
          size="medium"
          sx={{
            fontSize: "16px",
            [theme.breakpoints.up(400)]: { textWrap: "nowrap" },
          }}
        />
        {/* )} */}
        <Button
          icon={<TodayIcon />}
          label={t("match:chooseProfstep.card.chooseConsultation.chooseTime")}
          onClick={() => {
            setShowBookPane(true);
          }}
          size="medium"
          sx={{ [theme.breakpoints.up(400)]: { textWrap: "nowrap" } }}
        />
      </Box>
      {showBookPane && (
        <>
          <ChooseConsultation
            availability={availabilityV2}
            bookIntroConsult={bookIntroConsult}
            profId={profId}
          />
          <ConsultationRules transform="translateX(-94px)" />
        </>
      )}
    </Box>
  );
};

export default ProfCard;
