import { Box } from "@mui/material";
import { graphql } from "../../../api/__generated__";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import Header from "./Common/Header";
import StepHeader from "./Common/StepHeader";
import StepMainContent from "./Common/StepMainContent";
import BloomieCard from "./Common/BloomieCard";
import Footer from "./Common/Footer";

const topicsQuery = graphql(`
  query Topics {
    topics {
      descriptionTranslationKey
      id
      imageUrl
      titleTranslationKey
    }
  }
`);

const matchedHumanQuery = graphql(`
  query TopicStepMatchedHuman {
    currentMatchedHuman {
      id
      noTopic
      topic {
        id
      }
    }
  }
`);

const submitTopicStepMutation = graphql(`
  mutation SubmitTopicStep($topicId: String) {
    submitTopicStep(topicId: $topicId) {
      matchedHuman {
        id
        noTopic
        topic {
          id
        }
        treatments {
          availableTreatments
          module {
            id
          }
          proposedTreatment
        }
      }
      matchingToken
      nextStep
    }
  }
`);

interface TopicStepProps {
  setNextStep: (nextStep: string) => void;
  stepBack: () => void;
}

const TopicStep = ({ setNextStep, stepBack }: TopicStepProps) => {
  const { t } = useTranslation();
  const { data: topicsData } = useQuery(topicsQuery);

  useQuery(matchedHumanQuery, {
    onCompleted: (data) => {
      const matchedHuman = data.currentMatchedHuman;

      if (matchedHuman?.noTopic) {
        setChoice(null);
      } else {
        setChoice(matchedHuman?.topic?.id);
      }
    },
  });
  const [submitTopicStep] = useMutation(submitTopicStepMutation);
  const [choice, setChoice] = useState<string | null | undefined>();

  return (
    <Box sx={{ height: "100%", textAlign: "center", width: "100%" }}>
      <Header />
      <StepHeader
        imageUrl="https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D67-growth%2C+Size%3DSmall.png"
        subtitle={t("match:topicStep.subTitle")}
        title={t("match:topicStep.title")}
      />
      <StepMainContent
        enableNext={choice !== undefined}
        onClickNext={async () => {
          const { data: submitData } = await submitTopicStep({
            variables: { topicId: choice },
          });

          submitData?.submitTopicStep.nextStep &&
            setNextStep(submitData.submitTopicStep.nextStep);

          submitData?.submitTopicStep.matchingToken &&
            localStorage.setItem(
              "matchingToken",
              submitData.submitTopicStep.matchingToken,
            );
        }}
        onClickPrevious={stepBack}
      >
        <Box
          sx={{
            alignItems: "baseline",
            display: "flex",
            flexWrap: "wrap",
            gap: "35px 62px",
            justifyContent: "center",
            maxWidth: "1100px",
            paddingY: "1rem",
          }}
        >
          {topicsData?.topics.map((topic) => (
            <BloomieCard
              bodyText={t(topic.descriptionTranslationKey)}
              checked={topic.id === choice}
              imgUrl={topic.imageUrl}
              key={topic.id}
              onClick={() => {
                setChoice(topic.id);
              }}
              title={t(topic.titleTranslationKey)}
              type="outlined"
            />
          ))}
          <BloomieCard
            bodyText={t("topics:something_else.description")}
            checked={choice === null}
            imgUrl={
              "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D63-dont-know%2C+Size%3DSmall.png"
            }
            key={0}
            onClick={() => {
              setChoice(null);
            }}
            title={t("topics:something_else.title")}
            type="outlined"
          />
        </Box>
      </StepMainContent>
      <Footer />
    </Box>
  );
};

export default TopicStep;
