import { Box, Typography, useTheme } from "@mui/material";
import { graphql } from "../../../../api/__generated__";
import { useQuery } from "@apollo/client";
import { IntroDetails } from "../Match";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../providers/auth/AuthContext";
import StepHeader from "../Common/StepHeader";
import StepMainContent from "../Common/StepMainContent";
import Footer from "../Common/Footer";
import { useTranslation } from "react-i18next";
import ProfCard from "./ProfCard";
import Divider from "./Divider";
import Button from "../../../ui/buttons/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import ConsultationRules from "./ConsultationRules";

const proposedMatchesQuery = graphql(`
  query ProposedMatches {
    currentMatchedHuman {
      id
      proposedMatches {
        id
        ...MatchProf
      }
      subtopic {
        id
        titleTranslationKey
      }
    }
  }
`);

const organizationQuery = graphql(`
  query OrganizationForMatching($emailOrCode: String!) {
    organizationByEmailOrCode(emailOrCode: $emailOrCode) {
      id
      ...MatchOrganization
    }
  }
`);

const ChooseProfStep = ({
  setIntroDetails,
  setNextStep,
  bookIntroConsult,
}: {
  bookIntroConsult: () => Promise<void>;
  setIntroDetails: (introDetails: IntroDetails) => void;
  setNextStep: (nextStep: string) => void;
}) => {
  const { data: matchesData, loading } = useQuery(proposedMatchesQuery, {
    fetchPolicy: "network-only",
  });

  const [timeoutCompleted, setTimeoutCompleted] = useState(false);
  const [showExtra, setShowExtra] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTimeoutCompleted(true);
    }, 4000);
  }, []);

  const localCode = localStorage.getItem("orgCode");

  const { data: organizationData } = useQuery(organizationQuery, {
    skip: !localCode,
    variables: { emailOrCode: localCode ?? "" },
  });

  const theme = useTheme();
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthContext);

  const book = async (introDetails: IntroDetails) => {
    setIntroDetails(introDetails);

    if (currentUser) {
      await bookIntroConsult();

      setNextStep("endMatching");
    } else {
      setNextStep("register");
    }
  };

  if (!timeoutCompleted || loading) {
    return (
      <>
        <StepHeader
          imageUrl={
            "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D48-talk-to-professional%2C+Size%3DSmall.png"
          }
          subtitle={
            matchesData?.currentMatchedHuman?.subtopic?.titleTranslationKey &&
            t(matchesData.currentMatchedHuman.subtopic.titleTranslationKey)
          }
          title={t("match:treatmentStep.treatments.coaching.title")}
        />
        <StepMainContent>
          <Box
            component={"img"}
            src="https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D76-selfhelp%2C+Size%3DSmall.png"
            sx={{
              maxHeight: "320px",
              maxWidth: "520px",
              [theme.breakpoints.down(500)]: {
                maxHeight: "190px",
                maxWidth: "312px",
              },
            }}
          />
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h2">
              {t("match:loadingStep.body.title")}
            </Typography>
            <Typography variant="body2">
              {t("match:loadingStep.body.caption")}
            </Typography>
          </Box>
        </StepMainContent>
        <Footer />
      </>
    );
  } else {
    return (
      <Box sx={{ height: "100%", textAlign: "center", width: "100%" }}>
        <StepHeader
          imageUrl={
            "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D75-coaching%2C+Size%3DSmall.png"
          }
          subtitle={
            matchesData?.currentMatchedHuman?.subtopic?.titleTranslationKey &&
            t(matchesData.currentMatchedHuman.subtopic.titleTranslationKey)
          }
          title={t("match:treatmentStep.treatments.coaching.button")}
        />
        <StepMainContent>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              padding: "40px 200px",
              [theme.breakpoints.down(950)]: {
                padding: "40px 40px",
              },
            }}
          >
            <Typography
              sx={{ marginBottom: "50px", whiteSpace: "pre-line" }}
              variant="body1"
            >
              {t("match:chooseProfStep.body.disclaimer")}
            </Typography>
            {matchesData?.currentMatchedHuman?.proposedMatches
              .slice(0, showExtra ? 6 : 3)
              .map((professional, index, row) =>
                organizationData?.organizationByEmailOrCode ? (
                  <>
                    <ProfCard
                      bookIntroConsult={book}
                      chosenSubTopicId={
                        matchesData?.currentMatchedHuman?.subtopic?.id
                      }
                      key={index}
                      organization={organizationData.organizationByEmailOrCode}
                      prof={professional}
                    />
                    {index + 1 !== row.length && <Divider />}
                  </>
                ) : (
                  <>
                    <ProfCard
                      bookIntroConsult={book}
                      chosenSubTopicId={
                        matchesData?.currentMatchedHuman?.subtopic?.id
                      }
                      key={index}
                      prof={professional}
                    />
                    <Divider />
                  </>
                ),
              )}
          </Box>
          {!showExtra && (
            <Button
              fullyColored
              icon={<RefreshIcon style={{ fontSize: "18px" }} />}
              label={t("match:chooseProfStep.showMore")}
              onClick={() => {
                setShowExtra(true);
              }}
              style={{
                fontSize: "18px",
              }}
            />
          )}
          <ConsultationRules />
        </StepMainContent>
        <Footer />
      </Box>
    );
  }
};

export default ChooseProfStep;
